/*------------------------------------------------------------------------*/
/*  Rodesk empty module script
/*------------------------------------------------------------------------*/

window.rodeskMap = (() => {
    let settings;
    let map;
    let icon;

    const markerList = [];
    const defaults = {
        map: '#js-mark-map',

        attr: {
            fieldmarketeers: 'data-json-fieldmarketeers'
        }
    };

    /**
     * Initialize Leaflet map to show fieldmarketeers
     *
     */
    const _initMap = () => {
        const $map = $(settings.map);
        if (typeof L === 'object' && $map.length > 0) {
            // Set up the map
            map = L.map('js-mark-map', {
                center: [52.01531743663362, 4.88616943359375],
                zoom: 7,
                scrollWheelZoom: false,
                doubleClickZoom: false,
                boxZoom: false,
                attributionControl: false
            });

            // Set the type of icon
            icon = L.divIcon({ className: 'map__marker' }); // div icon (has to be styles manually, not visible by default.)

            // Set the tile layer for the map. See below for others.
            // http://leaflet-extras.github.io/leaflet-providers/preview/index.html
            L.tileLayer('http://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png', {
                attribution:
                    '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> &copy; <a href="http://cartodb.com/attributions">CartoDB</a>',
                subdomains: 'abcd',
                maxZoom: 19
            }).addTo(map);

            const apiUrl = $map.attr(settings.attr.fieldmarketeers);

            // Load the markers through a GET request and add them to the map
            $.getJSON(apiUrl, data => {
                let i = 0;

                // Add new markers every x
                const markerInterval = window.setInterval(() => {
                    const lat = data[i].latitude;
                    const lon = data[i].longitude;

                    let markerGroup;

                    const marker = L.marker([lat, lon], {
                        icon,
                        clickable: false
                    }).addTo(map);

                    markerList.push(marker);

                    i += 1;

                    // Stop when we've reached the last item
                    if (i === data.length) {
                        // Make a group of the markers so we can fit them on the map
                        markerGroup = new L.FeatureGroup(markerList);
                        map.fitBounds(markerGroup.getBounds());

                        // Stop the interval
                        window.clearInterval(markerInterval);

                        // On resize, fit the bounds again
                        $(window).on(
                            'resize',
                            $.debounce(250, () => {
                                if (typeof markerGroup === 'object') {
                                    map.fitBounds(markerGroup.getBounds());
                                }
                            })
                        );
                    }
                }, 50);
            });
        }
    };

    const _setup = () => {
        _initMap();
    };

    /**
     * Init module
     */
    const init = options => {
        // Setup settings.
        options = options || {};
        settings = $.extend({}, defaults, options);

        _setup();
    };

    // Return public functions
    return {
        init // Init this function
    };
})(jQuery); // Fully reference jQuery after this point.
