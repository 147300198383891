/*------------------------------------------------------------------------*/
/*  Rodesk inview functions
/*------------------------------------------------------------------------*/

window.rodeskValidate = (() => {
    let settings, $formElement;

    /**
     * Default settings
     */

    const defaults = {
        form: '.js-validate',
        formParent: '.c-form-wrapper',
        button: '.c-btn',
        itemsToHide: '.js-to-hide',
        responseMessage: '.js-succes-message',
        errorMessage: '.c-message',
        formItem: '.o-form__item',
        loader: '.c-loader',

        classes: {
            succes: 'is-succes',
            error: 'is-error',
            valid: 'is-valid',
            loading: 'is-loading',
            disabled: 'is-disabled',
            validate: 'c-form-validate'
        },

        dataAttr: {
            loadText: 'data-loading-text'
        }
    };

    /*------------------------------------------------------------------------*/
    /*  Send the form with AJAX
    /*------------------------------------------------------------------------*/

    function _sendForm($formNode) {
        const $formParent = $formNode.closest(settings.formParent);
        const $inputs = $formNode.find('input, textarea');
        const $button = $formNode.find(settings.button);
        const $thanksMessage = $formParent.find(settings.responseMessage);
        const $errorMessage = $formParent.find(settings.errorMessage);
        const $loader = $formParent.find(settings.loader);
        const data = $formNode.serialize();
        const buttonText = $button.text();

        // Show loader
        $loader.show();

        // Add loading class to form
        $formNode.addClass(settings.classes.loading);

        // Disbale all input while sending AJAX request
        $inputs.attr('disabled', true);
        $button.attr('disabled', true);

        // Add disbaled class to the input and button fields
        $inputs.addClass(settings.classes.disabled);
        $button.addClass(settings.classes.disabled);

        // Use data attirbute for alternative loading text
        $button.text($button.attr(settings.dataAttr.loadText));

        // AJAX login method
        $.ajax({
            type: 'POST',
            data,
            success: response => {

                // Hide loader
                $loader.hide();

                // Add loading class to form
                $formNode.removeClass(settings.classes.loading);

                // Remove disbaled class to the input fields
                $inputs.removeClass(settings.classes.disabled);
                $button.removeClass(settings.classes.disabled);

                // Reset button text
                $button.text(buttonText);

                // Enable all input while sending AJAX request
                $inputs.attr('disabled', false);
                $button.attr('disabled', false);

                // Check if the response is failed
                if(response.data.messages && !response.success) {

                    // Remove old error messages
                    $errorMessage.remove();

                    // Show new error messages
                    $formNode.prepend(response.data.messages);

                } else {

                    // Hide the form if response is success
                    $(settings.itemsToHide).hide();

                    // Show the succes message if response is success
                    $thanksMessage.show();
                }
            },
            error: error => {
                throw error('Error sending data', error);
            }
        });

        return false;
    }

    /*------------------------------------------------------------------------*/
    /*  Bind Parsley validate events
    /*------------------------------------------------------------------------*/

    function _bindEvents() {
        // First check if element excists before initing Parsley
        // Parsely alsways needs an excisting element
        if ($formElement.length > 0) {
            // Prevent default form submission
            $formElement.on('submit', event => {
                event.preventDefault();

                // Submit the form
                _sendForm($formElement);
            });

            // Run when a field validates with an error
            window.Parsley.on('field:error', fieldInstance => {
                const $errorContainer = $('.js-message-formerrors');
                const messages = fieldInstance.getErrorsMessages();

                // const fieldName = fieldInstance.$element.data('fieldname'); //TODO: Remove this const when not used.
                // const fieldClass = fieldInstance.$element.attr('name'); //TODO: Remove this const when not used.

                // Check if the are any message
                // If true show the error container
                if (messages) {
                    $errorContainer.show();
                }
            });

            // Run when a field validates with success
            window.Parsley.on('field:success', () => {
                const $errorContainer = $('.js-message-formerrors');
                const $errors = $errorContainer.find('li');

                if ($errors.length === 0) {
                    $errorContainer.hide();
                }
            });
        }
    }

    /**
     * Setup the module
     * Set the window heigt, animation elements
     * Use a timeout the set the initial check in view
     */

    const _setup = () => {
        $formElement = $(settings.form);

        if ($formElement.length > 0) {
            // Init Parsleyjs
            $formElement.parsley({
                errorClass: settings.classes.error,
                successClass: settings.classes.valid,
                errorsContainer: () => {
                    return $('<div></div>').css('display', 'none');
                },
                classHandler: el => {
                    return el.$element.parent();
                }
            });

            // Validate the form
            _bindEvents();
        }
    };

    /**
     * Init the module
     */
    const init = options => {
        // Setup settings.
        options = options || {};
        settings = $.extend({}, defaults, options);

        // Setup the module
        _setup();
    };

    /**
     * Return an object exposed to the public
     */
    return {
        init
    };
})();
