/*------------------------------------------------------------------------*/
/*  Rodesk slick carousel module script
/*------------------------------------------------------------------------*/

window.rodeskSlickCarousel = (() => {
    let settings, $carousel, mousePosition;

    const defaults = {
        carousel: '.js-carousel',
        carouselDots: '.slick-dots',
        carouselDotsContainer: '.c-carousel__dots',
        carouselPrev: '.js-carousel-prev',
        carouselNext: '.js-carousel-next',
        carouselSlide: '.slick-slide',
        carouselDelay: 0,

        carouselSettings: {
            arrows: false,
            accessibility: false,
            centerMode: false,
            centerPadding: 0,
            dots: true,
            draggable: false,
            slidesToShow: 1,
            lazyLoad: 'ondemand',
            useCSS: true,
            infinite: false,
            fade: true,
            speed: 300,
            autoplay: true,
            autoplaySpeed: 5000,
            adaptiveHeight: true,
            swipe: true,
            pauseOnFocus: true,
            pauseOnHover: true
        },

        callBacks: {
            init: () => {}
        }
    };

    /**
     * Calc top position of carousel navigation
     *
     */
    const _initSlick = () => {
        $carousel = $(settings.carousel);

        $carousel.on('init', settings.callBacks.init);

        $carousel.each((i, element) => {
            $(element).slick({
                arrows: settings.carouselSettings.arrows,
                accessibility: settings.carouselSettings.accessibility,
                dots: settings.carouselSettings.dots,
                centerMode: settings.carouselSettings.centerMode,
                centerPadding: settings.carouselSettings.centerPadding,
                draggable: settings.carouselSettings.draggable,
                slidesToShow: settings.carouselSettings.slidesToShow,
                lazyLoad: settings.carouselSettings.lazyLoad,
                useCSS: settings.carouselSettings.useCSS,
                infinite: settings.carouselSettings.infinite,
                fade: settings.carouselSettings.fade,
                speed: settings.carouselSettings.speed,
                autoplay: settings.carouselSettings.autoplay,
                autoplaySpeed: settings.carouselSettings.autoplaySpeed,
                adaptiveHeight: settings.carouselSettings.adaptiveHeight,
                swipe: settings.carouselSettings.swipe,
                pauseOnFocus: settings.carouselSettings.pauseOnFocus,
                pauseOnHover: settings.carouselSettings.pauseOnHover,
                prevArrow: $(element)
                    .parent()
                    .find(settings.carouselPrev),
                nextArrow: $(element)
                    .parent()
                    .find(settings.carouselNext),
                responsive: settings.carouselSettings.responsive,
                rows: 0
            });
        });
    };

    const _mouseMoving = event => {
        const windowCenter = $(window).width() / 2;
        const { clientX } = event.originalEvent;

        mousePosition = clientX <= windowCenter ? 'left' : 'right';

        if (mousePosition === 'left') {
            $carousel.removeClass('c-carousel__arrow--next');
            $carousel.addClass('c-carousel__arrow--prev');
        } else {
            $carousel.removeClass('c-carousel__arrow--prev');
            $carousel.addClass('c-carousel__arrow--next');
        }
    };

    const _clickNav = () => {
        if (mousePosition === 'left') {
            $carousel.slick('slickPrev');
        } else {
            $carousel.slick('slickNext');
        }
    };

    const _bindEvents = () => {
        $(settings.carousel).on('click', _clickNav);
        $(settings.carousel).on('mousemove', _mouseMoving);
    };

    const destroyCarousel = () => {
        if ($carousel.hasClass(settings.classes.slickInitialized)) {
            $carousel.slick('unslick');
            $carousel.off('click');
            $carousel.off('mousemove');
        }
    };

    const _setup = () => {
        _bindEvents();
        _initSlick();
    };

    /**
     * Init module
     */
    const init = options => {
        // Setup settings.
        options = options || {};
        settings = $.extend(true, {}, defaults, options);

        if ($(settings.carousel).length > 0) {
            _setup();
        }
    };

    // Return public functions
    return {
        init, // Init this function
        destroyCarousel
    };
})(); // Fully reference jQuery after this point.
