/*------------------------------------------------------------------------*/
/*  Rodesk empty module script
/*------------------------------------------------------------------------*/

window.rodeskHeaderColor = (() => {
    let settings, isChanged;

    const defaults = {
        header: '.js-header',
        scrollOffset: 2,

        classes: {
            changeColor: 'is-changed'
        }
    };

    /**
     * Check scroll position of user.
     *
     */
    const _checkScrollPos = () => {
        const header = document.querySelector(settings.header);
        const scrollPos = window.pageYOffset;

        if (scrollPos >= settings.scrollOffset && !isChanged) {
            header.classList.add(settings.classes.changeColor);
            isChanged = true;
        } else if (scrollPos < settings.scrollOffset && isChanged) {
            header.classList.remove(settings.classes.changeColor);
            isChanged = false;
        }
    };

    /**
     * Bind reveal events
     *
     */
    const _bindEvents = () => {
        // Bind event to window resize
        $(window).on('resize', $.debounce(100, _checkScrollPos));

        // Bind event to window scroll
        $(window).on('scroll', $.throttle(100, _checkScrollPos));
    };

    const _setup = () => {
        _bindEvents();
        _checkScrollPos();
    };

    /**
     * Init module
     */
    const init = options => {
        // Setup settings.
        options = options || {};
        settings = $.extend({}, defaults, options);

        isChanged = false;

        _setup();
    };

    // Return public functions
    return {
        init // Init this function
    };
})(jQuery); // Fully reference jQuery after this point.
