(() => {
    const { Transition } = Highway.default;
    const loader = document.querySelector('.o-loader');
    const loaderImage = document.querySelector('.o-loader__image');

    let timeoutLoader;
    let loaderVisible;

    class DefaultTransition extends Transition {
        out({ from, done }) {
            // Set loader and view to default position
            loader.style.opacity = '0';
            loader.style.visibility = 'visible';
            from.style.transform = 'translateY(0) translateX(0)';
            from.style.opacity = 1;
            loaderVisible = false;

            anime({
                targets: loader,
                opacity: 1,
                duration: 1000,
                easing: 'easeOutCubic',

                begin() {
                    rodeskMenu.closeMenu();

                    anime({
                        targets: from,
                        translateY: '5%',
                        opacity: 0,
                        duration: 1000,
                        easing: 'easeOutCubic',

                        complete() {
                            from.removeAttribute('style');
                            done();
                        }
                    });
                }
            });

            timeoutLoader = window.setTimeout(() => {
                anime({
                    targets: loaderImage,
                    opacity: [0, 1],
                    duration: 550,
                    easing: 'cubicBezier(.42,0,.58,1)',
                    begin() {
                        loaderVisible = true;
                    }
                });
            }, 2000);
        }

        in({ from, to, done }) {
            // clear loader timeOut;
            window.clearTimeout(timeoutLoader);

            // Set loader and view to default position
            loader.style.opacity = '1';
            loader.style.visibility = 'visible';
            to.style.transform = 'translateY(5%) translateX(0)';
            to.style.opacity = 0;

            window.scrollTo(0, 0);

            // Remove Old view
            from.remove();

            anime({
                targets: loader,
                opacity: [1, 0],
                duration: 1000,
                easing: 'easeOutCubic',

                begin() {
                    if (loaderVisible) {
                        anime({
                            targets: loaderImage,
                            opacity: [1, 0],
                            duration: 200,
                            easing: 'cubicBezier(.42,0,.58,1)',

                            begin() {
                                loaderVisible = false;
                            }
                        });
                    }

                    anime({
                        targets: to,
                        translateY: ['5%', '0%'],
                        opacity: [0, 1],
                        duration: 1000,
                        easing: 'easeOutCubic',
                        complete() {
                            done();
                            to.removeAttribute('style');
                            loader.style.visibility = 'hidden';
                            loader.style.opacity = '0';
                            loaderImage.style.opacity = '0';
                        }
                    });
                }
            });
        }
    }

    Transitions.exports({ DefaultTransition });
})();
