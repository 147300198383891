window.transitionManager = (() => {
    const { Core } = Highway.default;
    // const { CustomRenderer } = Renderers.default;
    const { DefaultTransition } = Transitions.default;

    const transitionManager = new Core({
        // renderers: {
        //     default: CustomRenderer
        // },
        transitions: {
            default: DefaultTransition
        }
    });

    return transitionManager;
})();
