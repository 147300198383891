(() => {
    const { Renderer } = Highway.default;
    class CustomRenderer extends Renderer {
        // onEnter() {}
        // onLeave() {}
        // onEnterCompleted() {}
        // onLeaveCompleted() {}
    }

    Renderers.exports({ CustomRenderer });
})();
