/*------------------------------------------------------------------------*/
/*  Rodesk head module
/*------------------------------------------------------------------------*/

window.rodeskHead = (() => {
    /*
     * Use this class to update head on
     *
     *
     */
    class RodeskHead {
        _updateHtmlClasses(to) {
            document.documentElement.classList = to.page.documentElement.classList;
            document.documentElement.setAttribute('lang', to.page.documentElement.getAttribute('lang'));
        }

        _updateBodyClasses(to) {
            document.body.classList = to.page.body.classList;
        }

        _updateNavigation(to) {
            const currentLang = document.documentElement.getAttribute('lang');
            const newLang = to.page.documentElement.getAttribute('lang');

            if (currentLang !== newLang) {
                const header = document.querySelector('.c-header__content');
                const newHeader = to.page.body.querySelector('.c-header__content').cloneNode(true);
                header.replaceWith(newHeader);
            } else {
                const newNavigationItems = to.page.body.querySelector('.js-navigation-items').childNodes;
                [...newNavigationItems].forEach(node => {
                    if (node.nodeName === 'LI') {
                        const newNavigationLink = node.querySelector('a');
                        const newNavigationClasses = node.classList;
                        const oldNavigation = document.querySelector('.js-navigation-items');
                        const oldNavigationElement = oldNavigation.querySelector(
                            `a[href='${newNavigationLink.getAttribute('href')}']`
                        );
                        if (oldNavigationElement !== null && newNavigationLink.hasAttribute('href')) {
                            oldNavigationElement.parentElement.classList = newNavigationClasses;
                        }
                    }
                });
            }

            const langSwitch = document.querySelector('.c-languages--current');
            const newLangSwitchLink = to.page.body.querySelector('.c-languages--current').getAttribute('href');

            langSwitch.setAttribute('href', newLangSwitchLink);
        }

        _updateHeader(to) {
            document.querySelector('.c-header').classList = to.page.body.querySelector('.c-header').classList;
        }

        _updateMeta(to) {
            [].forEach.call(to.page.head.childNodes, node => {
                switch (node.nodeName) {
                    case 'META': {
                        const contentElement = document.querySelector(
                            `meta[property="${node.getAttribute('property')}"]`
                        );

                        const nameElement = document.querySelector(`meta[name="${node.getAttribute('name')}"]`);

                        if (contentElement !== null && node.hasAttribute('property')) {
                            contentElement.setAttribute('content', node.content);
                        }
                        if (nameElement !== null && node.hasAttribute('name')) {
                            nameElement.setAttribute('content', node.content);
                        }
                        break;
                    }
                    case 'LINK': {
                        const element = document.querySelector(`link[rel="${node.getAttribute('rel')}"]`);

                        if (element !== null && node.hasAttribute('rel') && node.getAttribute('rel') === 'canonical') {
                            element.setAttribute('href', node.getAttribute('href'));
                        } else if (
                            element == null &&
                            node.hasAttribute('rel') &&
                            node.getAttribute('rel') === 'canonical'
                        ) {
                            const link = document.createElement('link');

                            link.rel = 'canonical';
                            link.href = transitionManager.location.href;

                            document.head.appendChild(link);
                        }
                        break;
                    }
                    default:
                        break;
                }
            });
        }
        /**
         *
         *
         *
         */

        update(to) {
            this._updateNavigation(to);
            this._updateHtmlClasses(to);
            this._updateBodyClasses(to);
            this._updateHeader(to);
            this._updateMeta(to);
        }
    }

    return new RodeskHead();
})();
