const documentReady = () => {
    const html = document.documentElement;
    const matchHeightOptionsElements = ['.js-panorama-height', '.js-equalize-target'];
    html.classList.remove('wf-loading', 'no-js');

    rodeskDefaults.init(); // Init all default functions
    rodeskSmoothScroll.init('.js-smooth-scroll'); // Init smoothscroll plugin
    rodeskLazyLoad.init(); // Init the lazyload module
    rodeskVideo.init(); // Init video module
    rodeskInView.init(); // Init customselect
    rodeskValidate.init(); // Init validate module
    rodeskMap.init(); // Init map module
    rodeskHomeAnimation.init(); // Init Home Animation Module

    rodeskDefaults.matchHeightOptions(matchHeightOptionsElements, {
        byRow: false
    });

    rodeskSlickCarousel.init({
        carousel: '.js-image-carousel',
        carouselSettings: {
            fade: false,
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: false
        }
    });

    // Init prefetch pages with quicklink. See docs for more info: https://github.com/GoogleChromeLabs/quicklink
    quicklink({
        ignores: [uri => uri.includes('#')]
    });

    if (typeof documentReadyMain === 'function') {
        documentReadyMain();
    }
};

function removePageTransitions() {
    $('.o-loader').hide();

    const allLinks = document.querySelectorAll('a');
    // Remove Listener at Runtime
    [].forEach.call(allLinks, link => {
        // do whatever
        link.removeEventListener('click', transitionManager._navigate);
    });
}

document.addEventListener('DOMContentLoaded', () => {
    rodeskMenu.init(); // Init menu
    rodeskHeaderColor.init();

    if (typeof window.fetch === 'undefined') {
        removePageTransitions();
    }

    documentReady();
});

transitionManager.on('NAVIGATE_END', () => {
    documentReady();
});

transitionManager.on('NAVIGATE_OUT', () => {
    rodeskDefaults.destroyEvents();
});

transitionManager.on('NAVIGATE_IN', ({ to }) => {
    rodeskMenu.setActiveMenuItem();
    rodeskHead.update(to);
});
