/*------------------------------------------------------------------------*/
/*  Rodesk Home Animations Module
/*------------------------------------------------------------------------*/

window.rodeskHomeAnimation = (() => {
    let settings;

    const defaults = {
        header: '.js-header-animation',
        icon: '.js-panorama-icon'
    };

    const _changeTitle = () => {
        const panoramaText = document.querySelectorAll(settings.header);
        const panoramaContent = document.querySelector('.js-panorama-home-content');

        panoramaContent.style.opacity = 0;

        anime({
            targets: panoramaContent,
            opacity: [0, 1],
            duration: 1500,
            delay: 500,

            begin() {
                anime({
                    targets: panoramaText,
                    keyframes: [
                        { translateX: [-10, 0], opacity: [0, 1], duration: 200 },
                        { translateX: [0, -10], opacity: [1, 0], duration: 500, delay: 3500 }
                    ],
                    easing: 'linear',
                    delay: anime.stagger(4500),
                    loop: true
                });

                anime({
                    targets: settings.icon,
                    rotateX: [0, 180],
                    easing: 'linear',
                    duration: 1000,
                    delay: 3500,
                    loop: true
                });
            }
        });
    };

    const _setup = () => {
        if (document.querySelector('.js-panorama-home-content')) {
            _changeTitle();
        }
    };
    /**
     * Init module
     */
    const init = options => {
        // Setup settings.
        options = options || {};
        settings = $.extend({}, defaults, options);

        _setup();
    };

    // Return public functions
    return {
        init // Init this function
    };
})(); // Fully reference jQuery after this point.
